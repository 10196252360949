import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

import io from "socket.io-client";

import { Modal as AntdModal, Input as AntdInput, Form as AntdForm } from 'antd';

const url = process.env.REACT_APP_URL;

const socket = io.connect(process.env.REACT_APP_API_URL);

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  const CollaborationFormRef = useRef(null);
  const [isCollaborationModalVisible, setCollaborationModalVisible] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const showCollaborationModal = () => {
    if(!window.TogetherJS.running) {
      setCollaborationModalVisible(true);
    } else {
      window.TogetherJS(this);
    }
  };

  const handleCollaborationSubmit = async () => {
    try {
      const values = await CollaborationFormRef.current.validateFields()
      console.log("Collaboration Submitted values: ", values)
      const { team_member_id } = values;
      CollaborationFormRef.current.resetFields();
      setCollaborationModalVisible(false);
      window.TogetherJS(this);
      setTimeout(()=> {
        if(window.TogetherJS.running && window.TogetherJS.shareUrl()) {
          console.log(">>> shareUrl: ", window.TogetherJS.shareUrl())
          socket.emit("forwardTogetherjsShareUrl", {
            url: window.location.origin,
            team_member_id: Number(team_member_id),
            collaborationShareUrl: window.TogetherJS.shareUrl()
          })
        }
      }, 1500);
    } catch (e) {
      console.log("handleCollaborationSubmit has error: ", e)
    }
  }

  const handleCollaborationCancel = () => {
    setCollaborationModalVisible(false);
  };

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Looking for  <span className="text-color-primary">support?</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Our face to face call support provides ease of communication to help resolve issues quickly.
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile onClick={() => { window.open(url + `?fromUrl=${window.location.origin}&caller_name=Hamza&app_name=BusinessConnect`, '_blank')}}>
                    Start Call
                    </Button>
                  <Button tag="a" color="primary" wideMobile onClick={() => { window.open(url + '?isOnlyRecording=yes&&fromUrl=' + window.location.origin, '_blank')}}>
                    Leave Message
                  </Button>
                  <Button tag="a" color="primary" wideMobile onClick={() => { window.open(url + '?startChat=true&&fromUrl=' + window.location.origin, '_blank')}}>
                    Start Chat
                  </Button>
                  <Button tag="a" color="primary" wideMobile onClick={showCollaborationModal}>
                    Start Collaboration
                  </Button>  
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
          <AntdModal title="Invite Team Member" visible={isCollaborationModalVisible} okText="Submit" onOk={handleCollaborationSubmit} onCancel={handleCollaborationCancel}>
            <AntdForm {...layout} ref={CollaborationFormRef} name="control-ref">
              <AntdForm.Item
                  name="team_member_id"
                  label="Team Member Id"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Team Member Id"
                    },
                  ]}
              >
              <AntdInput type="number" />
              </AntdForm.Item>
            </AntdForm>
          </AntdModal>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;